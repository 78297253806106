<template>
	<div class="about">
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg" />
		<div class="about-Company-box1 jucenter">
			<div class="Company-box1-left">
				<div class="Company-title">
					Company
					<div class="Company-title-border alcenter">
						<div class="red"></div>
						<div class="blue"></div>
					</div>
				</div>
				<div class="Company-box1-item alcenter" style="margin-top: 0.22rem;">
					<img src="../../assets/image/ic_clinian.png" class="box1-item-left" />
					<div class="box1-item-right ">
						<div>{{info.information?.established}}</div>
						<div>Established in 2016</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_yuangong.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.employees}}<sup>+</sup></div>
						<div>Employees</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_renzheng.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.accredited}}</div>
						<div>Accredited</div>
					</div>
				</div>
				<div class="Company-box1-item alcenter">
					<img src="../../assets/image/ic_hegui.png" class="box1-item-left" />
					<div class="box1-item-right">
						<div>{{info.information?.compliant}}</div>
						<div>GLP-compliant</div>
					</div>
				</div>
			</div>
			<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_1" class="Company-box1-right" />
		</div>

		<div class="about-Company-box2">
			<div class="Company-title jucenter">
				SAIFULab’s Key Differentiators
				<div class="Company-title-border flex-center">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="box2-list first jucenter">
				<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_2" />
				<div class="box-lists" v-if="info.difference">
					<div class="lists-item" v-for="(item,index) in info.difference.slice(0,3)" :key="index">
						<div class="lists-item-first alcenter">
							<div class="index">0{{index+1}}</div>
							<div class="title">{{item.title}}
								<div class="lists-item-border alcenter">
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
						<div class="lists-item-second">
							<ul style="width: 2.1rem">
								<li>{{item.synopsis}}</li>
							</ul>
						</div>
					</div>

				</div>
			</div>


			<div class="box2-list flex box2-list2" v-if="info.difference">

				<div class="box-lists">
					<div class="lists-item" v-for="(item,index) in info.difference.slice(3,7)" :key="index">
						<div class="lists-item-first alcenter">
							<div class="alcenter">
								<div class="index">0{{index+ 4}}</div>
								<div class="title">{{item.title}}
									<div class="lists-item-border alcenter">
										<div></div>
										<div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="lists-item-second flex">
							<ul style="width: 2rem;">
								<li>{{item.synopsis}}</li>
							</ul>
						</div>
					</div>
				</div>
				<img :src="proxy.$axios.imgUrl + info.information?.about_show_img_3" />
			</div>

		</div>



		<div class="contactUs">
			<div class="Company-title jucenter">
				SAIFU Lab’s Facility
				<div class="Company-title-border flex-center">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<div class="about-Company-box3 flex-center">
				<div class="box3-item" v-for="(item,index) in info.lab" :key="index">
					<img :src="proxy.$axios.imgUrl + item.images" />
					<div class="title">{{item.title}}</div>
					<div class="border flex-center"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		getCurrentInstance,
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"
	const {
		proxy
	} = getCurrentInstance()



	onMounted(() => {
		EventBus.emit("mgheadr", 4)
		getTopimg()
		getInfo()
		getbottomInfo()
	})

	const contactUsList = ref([{
			title: 'Address',
			icon: require('../../assets/image/ic_dizlxwm.png'),
			content: 'address'
		},
		{
			title: 'Website',
			icon: require('../../assets/image/ic_webwz.png'),
			content: 'website'
		},
		{
			title: 'Telephone',
			icon: require('../../assets/image/ic_telphone.png'),
			content: 'telephone'
		},
		{
			title: 'E-mail',
			icon: require('../../assets/image/ic_emaillx.png'),
			content: 'e_mail'
		}
	])

	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('/api/index/getAboutData')
		info.value = res.data
	}

	const bottomInfo = ref({})

	async function getbottomInfo() {
		let res = await proxy.$axios.get('api/index/getTopBottom')
		bottomInfo.value = res.data.bottom
	}


	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'about'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg {
		width: 100%;
		height: auto;
		margin-top: 0.05rem;
		display: block;
	}

	.about-Company-box1 {
		width: 100%;
		margin: 0 auto;
		background: url('../../assets/image/bg_compbj.png');
		padding: 0.2rem 0;
	}

	.Company-title {
		font-weight: 500;
		font-size: 0.11rem;
		color: #000000;
		padding-bottom: 0.09rem;
		position: relative;
	}

	.Company-title-border {
		height: 0.02rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0.26rem;
	}

	.Company-title-border .red {
		height: 100%;
		width: 0.05rem;
		background-color: #E83328;
	}

	.Company-title-border .blue {
		height: 100%;
		width: 0.19rem;
		background-color: #008AD2;
		margin-left: 0.02rem;
	}


	.Company-box1-item {
		margin-top: 0.12rem;
		min-width: 2.31rem;
		padding-bottom: 0.11rem;
		border-bottom: 1px solid #E6E6E6;
	}

	.Company-box1-item .box1-item-left {
		width: 0.23rem;
		height: 0.23rem;
		display: block;
	}

	.Company-box1-item .box1-item-right {
		flex: 1;
		margin-left: 0.09rem;
	}

	.Company-box1-item .box1-item-right>div:first-child {
		font-weight: 500;
		font-size: 0.1rem;
		color: #000000;
		line-height: 0.08rem;
	}

	.Company-box1-item .box1-item-right>div:last-child {
		font-weight: 400;
		font-size: 0.03rem;
		color: #999999;
		line-height: 0.08rem;
		margin-top: 0.04rem;
	}

	.Company-box1-right {
		width: 2.06rem;
		height: 2.2rem;
		margin-left: 0.36rem;
		/* animation: boxMove 1.8s 1; */
	}


	@keyframes boxMove {
		0% {
			margin-left: 3rem;
			opacity: 0.2;
		}


		100% {
			margin-left: 0.36rem;
			opacity: 1;
		}
	}


	.about-Company-box2 {
		padding: 0.26rem 0;

	}

	.about-Company-box2 .Company-title-border {
		left: 50%;
		transform: translateX(-50%);
	}

	.about-Company-box2 .box2-list {
		margin-top: 0.24rem;
	}

	.about-Company-box2 .box2-list>img {
		width: 3.63rem;
		height: 1.8rem;
	}

	.about-Company-box2 .box2-list .box-lists {
		width: 50%;
		margin-left: 0.25rem;
		text-align: left;
	}

	/* .about-Company-box2 .box2-list.first .box-lists{
		animation: boxMove 1.8s 1;
	} */

	.about-Company-box2 .box2-list .lists-item:not(:first-child) {
		margin-top: 0.2rem;
	}

	.about-Company-box2 .box2-list2 .lists-item-first {
		justify-content: flex-end;
	}

	.about-Company-box2 .box2-list2 .lists-item-first>div {
		width: 2rem;
	}

	.about-Company-box2 .box2-list .lists-item-first .index {
		font-weight: 500;
		font-size: 0.19rem;
		color: #008AD2;
		line-height: 0.14rem;
		font-style: italic;
	}

	.about-Company-box2 .box2-list .lists-item-first .title {
		font-weight: 500;
		font-size: 0.09rem;
		color: #008AD2;
		line-height: 0.08rem;
		margin-left: 0.06rem;
		padding-left: 0.05rem;
		padding-bottom: 0.07rem;
		position: relative;
	}

	.about-Company-box2 .box2-list .lists-item-first .lists-item-border {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.about-Company-box2 .box2-list .lists-item-first .lists-item-border>div:first-child {
		width: 0.02rem;
		height: 0.02rem;
		background: #008AD2;
		border-radius: 50%;
	}

	.about-Company-box2 .box2-list .lists-item-first .lists-item-border>div:last-child {
		width: 0.27rem;
		height: 0.01rem;
		background: #008AD2;
		margin-left: 0.03rem;
	}

	.about-Company-box2 .box2-list .lists-item-second {
		font-weight: 400;
		font-size: 0.06rem;
		color: #444444;
		line-height: 0.09rem;
		margin-top: 0.11rem;
		justify-content: flex-end;
	}

	.about-Company-box2 .box2-list2 {
		width: 100%;
		justify-content: flex-end;
	}

	.about-Company-box2 .box2-list2 .box-lists {
		width: auto;
		margin-left: 0;
		margin-right: 0.25rem;
	}

	.about-Company-box3 {
		margin-top: 0.25rem;
	}

	.about-Company-box3 .box3-item {
		text-align: center;
		padding-bottom: 0.12rem;
		border: 1px solid #EEEEEE;
	}

	.about-Company-box3 .box3-item:not(:first-child) {
		margin-left: 0.13rem;
	}

	.about-Company-box3 .box3-item>img {
		width: 1.07rem;
		height: 0.81rem;
		display: block;
	}

	.about-Company-box3 .box3-item .title {
		font-weight: 500;
		font-size: 0.07rem;
		line-height: 0.07rem;
		margin-top: 0.1rem;
	}

	.about-Company-box3 .box3-item .border {
		width: 0.19rem;
		height: 0.02rem;
		background: #008AD2;
		margin: 0.07rem auto 0;
	}

	.contactUs .Company-title-border {
		left: 50%;
		transform: translateX(-50%);
	}
</style>